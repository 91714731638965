import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout } from '../components/common'
import config from '../utils/siteConfig'
import { device } from "../utils/device.js"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import {HeaderXXL, BodyText, BodyTextLarge} from "../utils/typography"
import { Link } from 'gatsby'


const NotFoundPage = () => (
    <Layout>
        <CenteredContent>
          <IndexContainer>
            <IndexHeader>
              <h2>404.</h2>
            </IndexHeader>
            <Container>
            Sorry the page you are for looking seems to have disappeared.
            <br/><br/>
            Go back to <MyLink to="/" ><span>Home</span></MyLink>
            </Container>
          </IndexContainer>
        </CenteredContent>
    </Layout>
)

const MyLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  color: inherit;
  display: inline-block;

  >span{
    &:after{
      display:block;
      content: '';
      border-top: transparent 3px;
      border-bottom: solid 3px ${config.textColorLight};
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }
  }


  &:hover{
    >span{
      &:after{
        transform: scaleX(1);
      }
    }
  }
`

const Container = styled("div")`
  width: 100%;
  min-height: 10vh;
  padding: 2em;
  color: ${config.textColorLight};
  background-color: ${config.color1};
  ${BodyTextLarge};
`

const IndexContainer = styled("div")`
  margin: 10rem 0;
`

const IndexHeader = styled("div")`
  margin: -0.25em 0.2em;
  padding: 0;
  z-index: 10;
  position: relative;
  ${HeaderXXL}

  >h2{
    margin: 0;
    padding: 0;
    display: block;
    color: ${config.textColorDark};
    }
`

const CenteredContent = styled("div")`
  padding: 1em 0;
  margin-left: auto;
  margin-right: auto;
  max-width: ${config.contentMaxWidth};
  width: 95%;
  min-height: 80vh;


  @media ${device.desktop} {
    max-width: calc(${config.contentMaxWidth} * 1.2);
  }
`

export default NotFoundPage
